function updatePartnersSliderWidth() {
  const animationSlider = document.querySelectorAll(".carousel__wrapper");
  if (!animationSlider) return;

  animationSlider.forEach(
    (slider) => (slider.style.width = `${slider.scrollWidth + 20}px`)
  );
}

window.addEventListener("resize", () => {
  setTimeout(() => {
    updatePartnersSliderWidth();
  }, 300);
});
